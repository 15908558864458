import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Divider,
  Input,
  InputNumber,
  Popover,
  Select,
  Typography,
} from 'antd';
import isNil from 'lodash/isNil';
import {
  CustomRecordType,
  FilterType,
  FilterTypeEnum,
  chartKeyBlacklist,
} from '../experiment-list-layout-v2';
import capitalize from 'lodash/capitalize';
import uniqueId from 'lodash/uniqueId';
import { useFormulations } from '../../../_shared/context/formulations-context';
const { Text } = Typography;
const Filter = ({
  tableColumns,
  onRemove,
  onChange,
  filter,
}: {
  onChange: (changedFilter: FilterType) => void;
  filter: FilterType;
  tableColumns: CustomRecordType[];
  onRemove: () => void;
}) => {
  const items = tableColumns
    .filter(tc => !chartKeyBlacklist.includes(tc.key))
    .map(tc => {
      if (tc.columnType === 'outcome') {
        return [
          { value: tc.key, label: capitalize(tc.key) },
          {
            value: `${tc.key} importance`,
            label: `${capitalize(tc.key)} priority`,
          },
        ];
      }
      return { value: tc.key, label: capitalize(tc.key) };
    })
    .flat();

  const [campaignNames, setCampaignNames] = useState<
    { label: string; value: string }[]
  >();

  const { projectFormulations } = useFormulations();

  useEffect(() => {
    const newCampaignNames = projectFormulations.reduce<
      { label: string; value: string }[]
    >((acc, current) => {
      if (current.campaign && current.campaign.name) {
        const campaignName = current.campaign.name;
        if (!acc.some(option => option.value === campaignName)) {
          acc.push({ label: campaignName, value: campaignName });
        }
      }
      return acc;
    }, []);
    setCampaignNames(newCampaignNames);
  }, [projectFormulations]);

  return (
    <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Select
          style={{ width: '100%' }}
          allowClear
          placeholder="Select an option..."
          options={items}
          value={filter.selectedItem}
          onChange={value =>
            onChange({ ...filter, selectedItem: value as FilterTypeEnum })
          }
          status={filter.error ? 'error' : ''}
        />
        {filter.selectedItem !== 'source' &&
          filter.selectedItem !== 'initiative' &&
          !filter.selectedItem?.endsWith('importance') && (
            <Select
              defaultValue="between"
              style={{ width: '100%' }}
              onChange={value =>
                onChange({ ...filter, filterType: value as FilterTypeEnum })
              }
              value={filter.filterType}
              options={[
                { value: FilterTypeEnum.LESS_THAN, label: 'Less Than' },
                { value: FilterTypeEnum.GREATER_THAN, label: 'Greater Than' },
                { value: FilterTypeEnum.BETWEEN, label: 'Between' },
                { value: FilterTypeEnum.EQUAL_TO, label: 'Equal To' },
              ]}
            />
          )}
        {(filter.selectedItem === 'source' ||
          filter.selectedItem === 'initiative' ||
          filter.selectedItem?.endsWith('importance')) && (
          <Select
            style={{ width: '100%' }}
            onChange={value =>
              onChange({ ...filter, filterType: value as FilterTypeEnum })
            }
            value={
              filter.filterType === 'equalTo' ? filter.filterType : undefined
            }
            options={[{ value: FilterTypeEnum.EQUAL_TO, label: 'Equal To' }]}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {[FilterTypeEnum.GREATER_THAN, FilterTypeEnum.LESS_THAN].includes(
            filter.filterType
          ) && (
            <InputNumber
              style={{ width: '100%' }}
              value={filter.inputValue}
              onChange={value =>
                onChange({ ...filter, inputValue: value ?? 0 })
              }
              status={filter.error ? 'error' : ''}
            />
          )}
          {filter.filterType === FilterTypeEnum.EQUAL_TO &&
            filter.selectedItem !== 'source' &&
            filter.selectedItem !== 'initiative' &&
            !filter.selectedItem?.endsWith('importance') && (
              <Input
                style={{ width: '100%' }}
                value={filter.inputValue}
                onChange={e =>
                  onChange({ ...filter, inputValue: e.target.value ?? 0 })
                }
                status={filter.error ? 'error' : ''}
              />
            )}
          {filter.filterType === FilterTypeEnum.EQUAL_TO &&
            (filter.selectedItem === 'source' ||
              filter.selectedItem === 'initiative' ||
              filter.selectedItem?.endsWith('importance')) && (
              <Select
                style={{ width: '100%' }}
                value={filter.inputValue}
                onChange={value =>
                  onChange({ ...filter, inputValue: value ?? 0 })
                }
                status={filter.error ? 'error' : ''}
                options={
                  filter.selectedItem === 'source'
                    ? [
                        { value: 'PREFERRED', label: 'PREFERRED' },
                        { value: 'SUGGESTED', label: 'SUGGESTED' },
                        { value: 'TESTED', label: 'TESTED' },
                        { value: 'EXISTING', label: 'EXISTING' },
                      ]
                    : filter.selectedItem === 'initiative'
                    ? campaignNames
                    : filter.selectedItem.endsWith('importance')
                    ? [
                        { value: '3', label: 'High Priority' },
                        { value: '2', label: 'Medium Priority' },
                        { value: '1', label: 'Low Priority' },
                        { value: '0', label: 'None Priority' },
                      ]
                    : undefined
                }
              />
            )}
          {filter.filterType === FilterTypeEnum.BETWEEN && (
            <div style={{ display: 'flex', gap: 5 }}>
              <InputNumber
                style={{ width: 100 }}
                value={filter.startValue}
                onChange={value =>
                  onChange({ ...filter, startValue: value ?? 0 })
                }
                status={filter.error ? 'error' : ''}
              />
              <span style={{ marginTop: 5 }}>and</span>
              <InputNumber
                style={{ width: 100 }}
                value={filter.endValue}
                onChange={value =>
                  onChange({ ...filter, endValue: value ?? 0 })
                }
                status={filter.error ? 'error' : ''}
              />
            </div>
          )}
          <Button
            type="text"
            icon={<DeleteOutlined style={{ color: '#c8c8c8' }} />}
            onClick={onRemove}
          />
        </div>
      </div>

      <Text type="danger">{filter.error}</Text>
    </div>
  );
};

export const FilterDropdownV2 = ({
  filterMenuVisible,
  setFilterMenuVisible,
  tableColumns,
  formulationsToFilter,
  chartData,
  setFormulationsToFilter,
  chartFilters,
  setChartFilters,
  onClearFilters,
  tableData,
  tableDataForFilter,
}: {
  filterMenuVisible: boolean;
  setFilterMenuVisible: (filterMenuVisible: boolean) => void;
  tableColumns: CustomRecordType[];
  chartData: { name: string; values: Record<string, any>[] }[];
  formulationsToFilter: string[];
  setFormulationsToFilter: (formulationsToFilter: string[]) => void;
  chartFilters: FilterType[];
  setChartFilters: (filters: FilterType[]) => void;
  onClearFilters: () => void;
  tableData: CustomRecordType[];
  tableDataForFilter:
    | { name: string; values: Record<string, any>[] }[]
    | undefined;
}) => {
  const handleFilterRemove = (idToRemove: string) => {
    const filteredChartFilters = chartFilters.filter(
      filter => idToRemove !== filter.id
    );
    setChartFilters(filteredChartFilters);
    setFormulationsToFilter([]);
  };

  const handleAddFilter = () => {
    setChartFilters([
      ...chartFilters,
      {
        id: uniqueId(String(chartFilters.length)),
        filterType: FilterTypeEnum.GREATER_THAN,
        inputValue: 0,
        startValue: 0,
        endValue: 0,
        selectedItem: null,
      },
    ]);
  };

  const handleFilterChange = (updatedFilter: FilterType) => {
    const updatedData = chartFilters.map(filter => {
      if (filter.id === updatedFilter.id) {
        return updatedFilter;
      }
      return filter;
    });
    setChartFilters(updatedData);
  };

  const applyFilters = (): void => {
    //const formulationsToFilter: string[] = [];
    let updatedFilters = chartFilters.map(filter => ({ ...filter })); // Clone the chartFilters array
    let filtersHaveError = false;
    let updatedFormulationsToFilter: string[] = [];

    for (let i = 0; i < updatedFilters.length; i++) {
      let filter = updatedFilters[i];
      filter.error = undefined; // Reset previous error

      // Validation
      if (isNil(filter.selectedItem)) {
        filter.error = 'Selected item is required';
        filtersHaveError = true;
        continue;
      }

      // Check for BETWEEN filter specific validations
      if (filter.filterType === FilterTypeEnum.BETWEEN) {
        if (isNil(filter.startValue) || isNil(filter.endValue)) {
          filter.error = 'Start and end values are required for BETWEEN filter';
          filtersHaveError = true;
          continue;
        }
        if (filter.endValue < filter.startValue) {
          filter.error =
            'End value must be greater than start value in BETWEEN filter';
          filtersHaveError = true;
          continue;
        }
      } else if (isNil(filter.inputValue)) {
        // Check for other filters where inputValue is required
        filter.error = 'Input value is required';
        filtersHaveError = true;
        continue;
      }

      if (filter.selectedItem.endsWith('importance')) {
        let formulationSources = tableDataForFilter?.find(
          data => data.name === 'source'
        );
        if (formulationSources?.values)
          for (let item of formulationSources?.values) {
            if (item['source'] !== 'SUGGESTED') {
              updatedFormulationsToFilter.push(item.name);
            }
          }
      }

      let data = tableDataForFilter?.find(
        data => data.name === filter.selectedItem
      );
      if (isNil(data)) {
        filter.error = 'Data not found for selected item';
        filtersHaveError = true;
        continue;
      }

      for (const item of data.values) {
        switch (filter.filterType) {
          case FilterTypeEnum.BETWEEN:
            if (
              item[data.name] <= filter.startValue! ||
              item[data.name] >= filter.endValue!
            ) {
              updatedFormulationsToFilter.push(item.name);
            }
            break;
          case FilterTypeEnum.EQUAL_TO:
            if (String(filter.inputValue) === 'PREFERRED') {
              if (
                !item.originalData.formulation.formulationFeedback.some(
                  (feedback: { type: string; isActive: boolean }) =>
                    feedback.type === 'POSITIVE' && feedback.isActive
                )
              ) {
                updatedFormulationsToFilter.push(item.name);
              }
            } else if (String(item[data.name]) !== String(filter.inputValue)) {
              updatedFormulationsToFilter.push(item.name);
            }
            break;
          case FilterTypeEnum.GREATER_THAN:
            if (filter.inputValue! >= item[data.name]) {
              updatedFormulationsToFilter.push(item.name);
            }
            break;
          case FilterTypeEnum.LESS_THAN:
            if (filter.inputValue! <= item[data.name]) {
              updatedFormulationsToFilter.push(item.name);
            }
            break;
        }
      }
    }

    if (filtersHaveError) {
      setChartFilters(updatedFilters); // Set the errors
    } else {
      setChartFilters(updatedFilters); // Remove errors
      setFormulationsToFilter([
        ...formulationsToFilter,
        ...updatedFormulationsToFilter,
      ]);
      setFilterMenuVisible(false);
    }
  };

  const content = (
    <div style={{ width: 277 }}>
      <p>Show experiments where...</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          borderRadius: '10px',
          maxHeight: '400px',
          scrollbarWidth: 'thin',
          overflow: 'auto',
          gap: 5,
        }}
      >
        {chartFilters?.map((filter, index) => (
          <>
            {index > 0 && <Divider />}
            <Filter
              key={filter.id}
              tableColumns={tableColumns}
              onRemove={() => handleFilterRemove(filter.id)}
              filter={filter}
              onChange={handleFilterChange}
            />
          </>
        ))}
      </div>

      <Button
        icon={<PlusOutlined />}
        onClick={handleAddFilter}
        style={{ marginTop: 10 }}
      >
        Filter
      </Button>
      <Divider style={{ marginBottom: 10 }} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          danger
          type="default"
          icon={<DeleteOutlined />}
          onClick={() => {
            onClearFilters();
          }}
        >
          Clear All
        </Button>
        <div style={{ display: 'flex', gap: 5 }}>
          <Button onClick={() => setFilterMenuVisible(false)}>Cancel</Button>
          <Button type="primary" onClick={applyFilters}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
  return (
    <Popover
      content={content}
      open={filterMenuVisible}
      onOpenChange={setFilterMenuVisible}
      placement="bottom"
      trigger="click"
    >
      <Button icon={<FilterOutlined />}>
        Filters{' '}
        {chartFilters?.length > 0 ? (
          <Badge
            style={{ marginLeft: 5 }}
            shape="square"
            size="small"
            count={chartFilters.length}
          />
        ) : (
          ''
        )}
      </Button>
    </Popover>
  );
};
