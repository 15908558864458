import { IFormulationItemRaw, IFormulationRaw } from '../interfaces/formulationRaw.interfaces';
import {
  IFormulationRawRequest,
  IGetProjectOut,
  IGetProjectUserOut,
  IProjectCreateOut,
  IProjectEnableGoalSim,
} from '../interfaces/project.interfaces';
import { ProjectRepository } from '../repositories/project.repository';
import { useMutation, useQuery } from 'react-query';

export const useCreateProject = () => {
  const mutation = useMutation((data: IProjectCreateOut) => {
    return ProjectRepository.postCreateProject(data);
  });
  return mutation;
};

export const useFinishProject = () => {
  const mutation = useMutation((data: IProjectCreateOut) => {
    return ProjectRepository.postFinishSetupProject(data);
  });
  return mutation;
};

export const useUploadFormulationRaw = () => {
  const mutation = useMutation((data: IFormulationRaw) => {
    return ProjectRepository.postUploadRawFormulations(data);
  });
  return mutation;
};

export const useConfirmLargeFile = () => {
  const mutation = useMutation(
    (data: {
      projectId: string;
      fileId: string;
      confirmed: boolean;
      orgId: string;
    }) => {
      return ProjectRepository.postConfirmLargeFile(data);
    }
  );
  return mutation;
};
export const useUpdateProjectFromulationRaw = () => {
  const mutation = useMutation((data: IFormulationRaw) => {
    return ProjectRepository.putProjectFromulationRaw(data);
  });
  return mutation;
};

export const useDeleteFormulationRaw = () => {
  const mutation = useMutation((data: IFormulationRaw) => {
    return ProjectRepository.deleteRawFormulations(data);
  });
  return mutation;
};

export const useUpdateProjectFromulationItemRaw = () => {
  const mutation = useMutation((data: IFormulationItemRaw) => {
    return ProjectRepository.putUpdateRawFormulationItem(data);
  });
  return mutation;
};

export const useGetProjectById = (
  data: IGetProjectOut
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getProjectById`, data.organizationId, data.projectId],
    ProjectRepository.getProjectById,
    {
      enabled: !!data.organizationId && !!data.projectId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useGetProjectFormulationsRaw = (
  data: IFormulationRawRequest
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [
      `getProjectFormulationsRaw`,
      data.organizationId,
      data.projectId,
      data.page,
      data.pageSize,
    ],
    ProjectRepository.getProjectFromulationRaw,
    {
      enabled: !!data.organizationId && !!data.projectId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useDuplicateProject = () => {
  const mutation = useMutation((data: any) => {
    return ProjectRepository.postDuplicateProject(data);
  });
  return mutation;
};

export const useGetProjectSummary = (
  data: any
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getProjectSummary`, data.organizationId, data.projectId],
    ProjectRepository.getProjectSummary,
    {
      enabled: !!data.organizationId && !!data.projectId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useGetProjectGoalSimulatorStatus = (data: {
  organizationId?: string;
  projectId?: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getProjectGoalSimulatorStatus`, data.organizationId, data.projectId],
    ProjectRepository.getProjectGoalSimulatorStatus,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useEnableGoalSimulator = () => {
  const mutation = useMutation((data: IProjectEnableGoalSim) => {
    return ProjectRepository.postProjectEnableGoalSimulator(data);
  });
  return mutation;
};

export const useClearDetails = () => {
  const mutation = useMutation((data: any) => {
    return ProjectRepository.postClearData(data);
  });
  return mutation;
};

export const useGetEventHistory = (
  data: IGetProjectOut
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getEventHistory`, data.organizationId, data.projectId],
    ProjectRepository.getEventoHistory,
    {
      enabled: !!data.organizationId && !!data.projectId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};
export const useGetProjectUser = (
  data: IGetProjectUserOut
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getProjectUser`, data.organizationId, data.userId],
    ProjectRepository.getProjectShared,
    {
      enabled: !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};
