/** @jsxImportSource @emotion/react */

import { usenotifyCsOfDownloadMutation } from '../../../../../__generated__/globalTypes';
import posthog from 'posthog-js';
import { MouseEventHandler } from 'react';

export type DownloadFileButtonProps = {
  content: string;
  options: any;
  filename: string;
  onclick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  children: JSX.Element | string;
  dataForPostHog?: {
    projectId: string;
    initativeId: string;
  };
  notifyCs?: {
    testPlanId?: string;
  };
};

export const DownloadFile = (props: DownloadFileButtonProps) => {
  const [notifyCsOfDownload] = usenotifyCsOfDownloadMutation();

  const getDownloadUrl = () => {
    const csv = new Blob([props.content], props.options);
    return URL.createObjectURL(csv);
  };

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={getDownloadUrl()}
      download={props.filename}
      onClick={() => {
        console.log(props.dataForPostHog);
        if (props.dataForPostHog) {
          posthog.capture('DOWNLOAD_INITIATIVE', {
            data: {
              ...props.dataForPostHog,
            },
          });

          if (props.notifyCs?.testPlanId !== undefined)
            notifyCsOfDownload({
              variables: {
                testPlanId: props.notifyCs.testPlanId,
                fileName: props.filename,
              },
            });
        }
        props.onclick;
      }}
    >
      {props.children}
    </a>
  );
};
