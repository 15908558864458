import React, { useEffect, useState } from "react";
import { Tabs, Table } from "antd";

const { TabPane } = Tabs;

export enum TabsOption {
  MY_PROJECTS = 'My_Projects',
  MY_TEAM_PROJECTS = 'My_Team_Projects',
  ALL_PROJECTS = 'All_Projects',
}
type IProps = {
  activeTab: TabsOption
  setActiveTab: (key: TabsOption) => void
};

const ProjectListTabs = ({ activeTab, setActiveTab }: IProps) => {

  const handleOnChange = (key: any) => {
    setActiveTab(key)
  }

  return (
    <Tabs
      activeKey={activeTab}
      defaultActiveKey={activeTab} centered onChange={(key: any) => handleOnChange(key)}>
      <TabPane tab="My Projects" key={TabsOption.MY_PROJECTS}  >
      </TabPane>
      <TabPane tab="My Team Projects" key={TabsOption.MY_TEAM_PROJECTS}>
      </TabPane>
      <TabPane tab="All Projects" key={TabsOption.ALL_PROJECTS}>
      </TabPane>
    </Tabs>
  );
};

export default ProjectListTabs;
