import React, { useEffect, useState } from 'react';
import { FormulationType } from '../../../_shared/context/formulations-context';
import { useSession } from '../../../_shared/context';
import './formulation-item.less';
import { Empty, Flex, Table, Tooltip } from 'antd';
import { FormulationItemType } from '@prisma/client';
import {
  IngredientList,
  useformulationDetailQuery,
} from '../../../../../__generated__/globalTypes';
import {
  calculateFormulationCost,
  calculateFormulationCostV2,
} from '../../../_shared/utils/util';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { IngredientCompositionCard } from '../../../components/experiments/experiment-detail/ingredient-composition';
import { ColumnsType } from 'antd/es/table';

export const FormulationSelected = ({
  formulation,
  onHandleFormulation,
  performance,
}: {
  formulation: FormulationType | undefined;
  onHandleFormulation: (value: boolean) => void;
  performance?: any;
}) => {
  const { currentProject } = useSession();
  const [cost, setCost] = useState(0);
  const compositionTableData: any[] | undefined = [];

  const { data: formulationWithDesign, error } = useformulationDetailQuery({
    variables: {
      formulationId: formulation!.id,
    },
  });

  useEffect(() => {
    if (formulation) {
      const ingredientList = currentProject?.ingredientList as
        | IngredientList[]
        | undefined;

      let formulationIngredientItems = formulation.items
        .filter(item => item.type === FormulationItemType.INPUT)
        .map(item => ({
          value: Number(item.value),
          name: item.variable.name,
        }));

      const cost = Number(
        calculateFormulationCostV2(
          formulationIngredientItems,
          ingredientList ?? []
        )
      );
      setCost(cost);
    }
  }, [formulation]);

  const handelOnClose = () => {
    onHandleFormulation(false);
  };

  const outcomeTableColumns = [
    {
      title: 'Ingredient Composition',
      dataIndex: 'ingredientComposition',
      key: 'ingredientComposition',
    },
    {
      title: 'Total (%)',
      dataIndex: 'prediction',
      key: 'prediction',
    },
  ];

  if (currentProject && currentProject.ingredientComposition.length > 0) {
    const compositionValues: Map<string, { [id: string]: string }> = new Map();

    formulationWithDesign?.formulation?.ingredientCompositionTotals?.forEach(
      c => {
        if (c && c.name) {
          compositionValues.set(c.name, {
            ...(compositionValues.get(c.name) ?? {}),
            prediction: `${c.total?.toFixed(5)}%`,
            measured: `${c.total?.toFixed(5)}%`,
          });
        }
      }
    );

    formulationWithDesign?.formulation?.design?.constraints.forEach(c => {
      const ingredientComposition = currentProject?.ingredientComposition.find(
        ing => ing.id === c.ingredientCompositionId
      );
      if (ingredientComposition) {
        compositionValues.set(ingredientComposition.name, {
          ...(compositionValues.get(ingredientComposition.name) ?? {}),
          goal: `${c.lowerBounds} - ${c.upperBounds}`,
        });
      }
    });

    compositionValues.forEach((data, name) => {
      compositionTableData.push({
        key: name,
        ingredientComposition: name,
        ...data,
      });
    });
  }

  return formulation ? (
    <div className="formulation-item" id="formulation-item">
      <div className="formulation-box">
        <div className="header">
          <div className="title">
            {formulation.isBenchmark
              ? 'Benchmark'
              : formulation.name
              ? formulation.name
              : formulation.key}
          </div>
          <div id="close">
            <CloseOutlined onClick={handelOnClose} />
          </div>
        </div>

        {performance && (
          <>
            <div className="row cost-row">
              <div className="cost">Round</div>
              <div className="portion">{performance.formulation.round + 1}</div>
            </div>

            <div className="row cost-row">
              <div className="cost">Desirability</div>
              <div className="portion">
                {performance.desirability.toFixed(3)}
              </div>
            </div>
          </>
        )}
        {
          <div className="row cost-row">
            <div className="cost">Cost</div>
            <div className="portion">
              {currentProject?.monetaryUnit} {cost.toFixed(3)}
            </div>
          </div>
        }

        <div className="ingredient-container">
          <div className="ingredients">
            <Flex justify="space-between" align="center">
              <div className="title">Ingredients:</div>
            </Flex>
            {formulation.items.map(i => {
              if (i.type == FormulationItemType.INPUT) {
                return (
                  <div className="row">
                    {i.variable.name}{' '}
                    <span>
                      {isNaN(Number.parseFloat(i.value))
                        ? i.value
                        : Number.parseFloat(i.value).toFixed(3)}
                    </span>
                  </div>
                );
              }
            })}
          </div>
          <div className="ingredients">
            <Flex justify="space-between" align="center">
              <div className="title">Outcomes:</div>
            </Flex>
            {formulation.items.map(i => {
              if (i.type == FormulationItemType.TARGET_MEASURED) {
                return (
                  <div className="row">
                    {i.variable.name}{' '}
                    <span>
                      {isNaN(Number.parseFloat(i.value))
                        ? i.value
                        : Number.parseFloat(i.value).toFixed(3)}
                    </span>
                  </div>
                );
              }
            })}
            {formulation.isBenchmark &&
              formulation.items.map(i => {
                if (i.type == FormulationItemType.TARGET_MEASURED) {
                  return (
                    <div className="row">
                      {i.variable.name}{' '}
                      <span>
                        {isNaN(Number.parseFloat(i.value))
                          ? i.value
                          : Number.parseFloat(i.value).toFixed(2)}
                      </span>
                    </div>
                  );
                }
              })}
          </div>
        </div>
        {compositionTableData.length > 0 && (
          <Table
            columns={outcomeTableColumns}
            dataSource={compositionTableData}
            pagination={false}
            style={{ marginBottom: '10px' }}
            expandable={{
              defaultExpandAllRows: true,
              expandRowByClick: true,
              columnWidth: 0,
              fixed: true,
              // showExpandColumn: false,
              indentSize: 0,
              expandIcon: ({ expanded, onExpand, record }) => {
                if ('children' in record) {
                  return expanded ? (
                    <CaretDownOutlined className="expanderIcon" />
                  ) : (
                    <CaretRightOutlined className="expanderIcon" />
                  );
                }
                return '';
              },
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="formulation-item" id="formulation-item">
      <div className="formulation-box">
        <h3>Click on a cell to see the formulation details</h3>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    </div>
  );
};
