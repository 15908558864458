/** @jsxImportSource @emotion/react */
import './confirmModal.less';
import { useEffect, useState } from 'react';
import { Form, Button, Modal, InputNumber, Tooltip, Select, Input } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

type modalProps = {
  onClose: () => void;
  onConfirm: (data?: any) => void;
  title?: string;
  description?: string;
};
const { Option } = Select;

export const ConfirmationModal = ({
  onClose,
  onConfirm,
  title,
  description,
}: modalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const cancelBtn = (
    <Button
      key="back"
      onClick={onClose}
      disabled={loading}
      loading={loadingCancel}
    >
      Cancel
    </Button>
  );

  const handleOnCancel = () => {
    setLoadingCancel(true);
    onClose();
  };

  const handleOnConfirm = (data?: any) => {
    setLoading(true);
    onConfirm(data);
  };

  const saveCampaignBtn = (
    <Button
      loading={loading}
      className="primary-button"
      form="campaign-form"
      key="confirm"
      onClick={handleOnConfirm}
      disabled={loadingCancel}
    >
      {'Confirm'}
    </Button>
  );

  const footer = [cancelBtn, saveCampaignBtn];
  return (
    <Modal
      title={title ?? 'Confirmation Modal'}
      open={true}
      onCancel={onClose}
      footer={footer}
      centered
    >
      <div id="confirmation-modal">
        <p>{description}</p>
      </div>
    </Modal>
  );
};
