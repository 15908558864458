/** @jsxImportSource @emotion/react */

import { HistoryOutlined, SendOutlined } from '@ant-design/icons';
import { Collapse, Flex, Input, Segmented, Spin, Tag, Typography } from 'antd';
import { useState } from 'react';
import '../../support/contactTuringSupport.less';
import { useSendLunaChatMessage } from '../../../network/services/luna-chat.service';
import { useSession } from '../../../_shared/context';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

export const CopilotChat = () => {
  const { user, currentProject } = useSession();

  const [inputValue, setInputValue] = useState('');
  const sendMessage = useSendLunaChatMessage();

  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleSendMessage = () => {
    if (inputValue.trim() === '' || !user || !currentProject) {
      return;
    }
    setHasError(false);
    setLoading(true);
    setResponse('');
    sendMessage.mutate(
      {
        organizationId: user?.organizationId,
        projectId: currentProject.id,
        message: inputValue,
        userId: user.id,
      },
      {
        onSuccess: async (response?: any) => {
          if (!response) {
            setHasError(true);
          } else {
            setResponse(response.data.message);
          }
          setLoading(false);
        },
        onError: () => {
          setHasError(true);
          setLoading(false);
        },
      }
    );
  };

  return (
    <Flex
      style={{
        width: 450,
        paddingLeft: 15,
        height: 'calc(100vh - 400px)',
        overflowX: 'scroll',
      }}
      vertical
      gap={15}
    >
      <Typography.Title
        style={{ display: 'flex', alignItems: 'center', gap: 6, marginTop: 12 }}
        level={4}
      >
        Talk to Luna <Tag>Beta</Tag>
      </Typography.Title>
      {/**<Segmented
        options={[
          {
            label: 'Questions',
            value: 'questions',
          },
          {
            label: 'History',
            value: 'history',
            icon: <HistoryOutlined />,
          },
        ]}
        block
      />**/}
      <div className="chat-input-container" key={'chat-container'}>
        <Input
          placeholder="Ask a question"
          suffix={<SendOutlined onClick={handleSendMessage} />}
          value={inputValue}
          onChange={event => setInputValue(event.currentTarget.value)}
          onPressEnter={handleSendMessage}
          className="chat-input"
          disabled={loading}
        />
      </div>
      <Spin
        indicator={<LoadingOutlined spin />}
        spinning={loading}
        style={{ marginTop: 20 }}
      >
        {hasError && (
          <>There was an issue completing your request. Please try again.</>
        )}
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{response}</ReactMarkdown>
      </Spin>
      {loading && (
        <Typography.Text type="secondary">
          Luna is thinking. This may take 1-3 minutes. Thank you for your
          patience.
        </Typography.Text>
      )}
      <Collapse
        bordered={false}
        items={[
          {
            key: 'message',
            label: 'This feature is experimental. Read more...',
            children: (
              <>
                <Typography.Text type="secondary">
                  This feature uses a privately hosted LLM to help analyze your
                  formulation data. While we strive for accuracy, responses may
                  sometimes be incorrect or incomplete.
                </Typography.Text>

                <Typography.Title level={5} style={{ marginTop: 16 }}>
                  Works well for:
                </Typography.Title>
                <ul>
                  <li>
                    <Typography.Text>
                      Comparing specific formulations (e.g. "Compare T09 and
                      T10")
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text>
                      Finding formulations with extreme values (e.g. "Which
                      formulation has the highest cost?")
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text>
                      Analyzing ingredient amounts (e.g. "Show formulations with
                      water content above 30")
                    </Typography.Text>
                  </li>
                </ul>

                <Typography.Title level={5} style={{ marginTop: 16 }}>
                  May not work well for:
                </Typography.Title>
                <ul>
                  <li>
                    <Typography.Text>
                      Complex multi-step analysis
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text>
                      Questions requiring external knowledge
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text>
                      Hypothetical or predictive questions
                    </Typography.Text>
                  </li>
                </ul>
              </>
            ),
          },
        ]}
      />
    </Flex>
  );
};
