import { useSession } from '../../_shared/context';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const TuringUniversityRedirect: React.FC = () => {
  const location = useLocation();

  const { user } = useSession();

  const urls = {
    Kraft: 'https://university2.turingsaas.com/',
  };

  useEffect(() => {
    const path = location.pathname.replace('/turing-university/', '');
    if (path && user) {
      const organizationName = user?.organization.name!;
      window.location.href = `${
        urls[organizationName as keyof {}] ??
        'https://university.turingsaas.com/'
      }${path}`;
    }
  }, [location.pathname, user]);

  return null;
};
