import { GreenCheck } from '../../../../_shared/style';
import { Input, InputNumber } from 'antd';
import React, { useState, useEffect } from 'react';

interface EditableCellProps {
  data: { value: string, id: string };
  updateData?: (id: string, value: string) => void;
}


export const FormulationItemRawEditableCell = ({ data, updateData }: EditableCellProps) => {

  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState(data.value);
  const handleEdit = () => {
    data.value = value;
    setEditMode(false);

    if (updateData) {
      updateData(data.id, data.value);
    }
  }
  return (
    <>
      <div id='success-target-container'>
        {!editMode && <div className='flat-value' onClick={() => setEditMode(!editMode)}>
          {value}
        </div>}
        {editMode && <>
          <Input className="target-value" value={value} onChange={(e) => {
            setValue(e.target.value)
          }} />
          <div className="action-button" onClick={handleEdit}>
            <GreenCheck />
          </div>

        </>}
      </div>
    </>);

};