import { BaseProject } from '../../../_shared/hooks';
import { calculateFormulationCostV2 } from '../../../_shared/utils/util';
import {
  Formulation,
  FormulationItem,
  Objective,
} from '__generated__/globalTypes';

export const parseDesirabilityPerFormulation = (
  formulationsDesing: any,
  objective: Objective & { outcomeId: string },
  calculateCost: boolean,
  currentProject?: BaseProject
) => {
  let initiative: any = {};
  let costValues: any = {};
  const ingredientList = currentProject?.ingredientList;
  for (let key of Object.keys(formulationsDesing)) {
    const design: any = formulationsDesing[key][0];
    if (!initiative[design.initiative]) initiative[design.initiative] = {};
    const formulationItem = (design.formulationItems as Array<any>).filter(
      (fi: any) => {
        return fi.variable.name === objective.targetVariable;
      }
    )?.[0];
    if (formulationItem)
      initiative[design.initiative][design.round + 1] = {
        formulationItem: formulationItem?.value,
        formulationId: formulationItem?.formulationId,
      };

    if (calculateCost) {
      let quantities: { name: string; value: number }[] = [];
      Object.keys(design.quantities).map(key => {
        const i = currentProject?.ingredientList.find(
          i => key === i.ingredient.id.toString()
        );
        quantities.push({
          value: Number(design.quantities[key]),
          name: i?.ingredient.name ?? key,
        });
      });

      const cost = Number(
        calculateFormulationCostV2(quantities, ingredientList ?? [])
      );
      if (!costValues[design.initiative]) costValues[design.initiative] = {};
      if (!costValues[design.initiative][design.round + 1])
        costValues[design.initiative][design.round + 1] = {};
      costValues[design.initiative][design.round + 1] = {
        formulationItem: cost.toFixed(3),
      };
      if (formulationItem) {
        initiative[design.initiative][design.round + 1] = {
          formulationItem: formulationItem.value,
          formulationId: formulationItem.formulationId,
        };
      }
      if (calculateCost) {
        let quantities: { name: string; value: number }[] = [];
        Object.keys(design.quantities).map(key => {
          const i = currentProject?.ingredientList.find(
            i => key === i.ingredient.id.toString()
          );
          quantities.push({
            value: Number(design.quantities[key]),
            name: i?.ingredient.name ?? key,
          });
        });

        const cost = Number(
          calculateFormulationCostV2(quantities, ingredientList ?? [])
        );
        if (!costValues[design.initiative]) costValues[design.initiative] = {};
        if (!costValues[design.initiative][design.round + 1])
          costValues[design.initiative][design.round + 1] = {};
        costValues[design.initiative][design.round + 1] = {
          formulationItem: cost.toFixed(3),
          formulationId: formulationItem?.formulationId,
        };
      }
      if (calculateCost) {
        let quantities: { name: string; value: number }[] = [];
        Object.keys(design.quantities).map(key => {
          const i = currentProject?.ingredientList.find(
            i => key === i.ingredient.id.toString()
          );
          quantities.push({
            value: Number(design.quantities[key]),
            name: i?.ingredient.name ?? key,
          });
        });

        const cost = Number(
          calculateFormulationCostV2(quantities, ingredientList ?? [])
        );
        if (!costValues[design.initiative]) costValues[design.initiative] = {};
        if (!costValues[design.initiative][design.round + 1])
          costValues[design.initiative][design.round + 1] = {};
        costValues[design.initiative][design.round + 1] = {
          formulationItem: cost.toFixed(3),
          formulationId: formulationItem?.formulationId,
        };
      }
    }
  }

  return {
    initiative,
    costValues,
  };
};

export const parseDesirabilityPerOutcomeFormulation = (
  outcomes: any,
  objective: Objective & { outcomeId: string },
  calculateCost: boolean,
  currentProject?: BaseProject
) => {
  let initiative: any = {};
  let costInitiative: any = {};
  let costValues: any = {};
  const outcome: any = outcomes[objective.outcomeId];
  for (let iname of Object.keys(outcome)) {
    if (!initiative[iname]) initiative[iname] = {};
    if (!costInitiative[iname]) costInitiative[iname] = {};
    for (let round of Object.keys(outcome[iname])) {
      let roundNumber = Number(round) + 1;
      if (!initiative[iname][roundNumber]) initiative[iname][roundNumber] = {};
      if (!costInitiative[iname][roundNumber])
        costInitiative[iname][roundNumber] = {};
      initiative[iname][roundNumber] = {
        formulationItem: outcome[iname][round].value,
        desirability: outcome[iname][round].desirability,
        formulationId: outcome[iname][round].formulation,
      };

      costInitiative[iname][round] = outcome[iname][round].cost;

      if (calculateCost) {
        if (!costValues[iname]) costValues[iname] = {};
        if (!costValues[iname][roundNumber])
          costValues[iname][roundNumber] = {};
        costValues[iname][roundNumber] = {
          formulationItem: outcome[iname][round].cost.toFixed(3),
          desirability: outcome[iname][round].desirability,
          formulationId: outcome[iname][round].formulation_cost,
        };
      }
    }
  }

  return {
    initiative,
    costValues,
  };
};

export const parseBenchkmarkRowData = (
  benchmark: Formulation,
  objective: Objective,
  calculateCost: boolean,
  currentProject?: BaseProject
) => {
  let benchmarkCost = 0;
  if (calculateCost && benchmark) {
    let quantities: { name: string; value: number }[] = [];
    const ingredientList = currentProject?.ingredientList;
    Object.keys(benchmark?.quantities).map(key => {
      const i = currentProject?.ingredientList.find(
        i => key === i.ingredient.id.toString()
      );
      quantities.push({
        value: Number(benchmark?.quantities[key]),
        name: i?.ingredient.name ?? key,
      });
    });

    benchmarkCost = Number(
      calculateFormulationCostV2(quantities, ingredientList ?? [])
    );
  }

  return {
    benchmark: benchmark?.items?.filter(
      (item: FormulationItem) =>
        item.variable.outcomeMetadata?.targetVariable ===
        objective.targetVariable
    )?.[0]?.value,
    benchmarkCost,
  };
};
